<template>
  <section class="stock-provide-wrapper">
    <b-row class="provide-add">
      <b-col cols="12">
        <b-card no-body class="stock-preview-card">
          <!-- Header -->
          <b-card-body class="stock-padding pb-0">
            <div
              class="d-flex justify-content-between flex-md-row flex-column stock-spacing mt-0"
            >
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <h3 class="text-primary stock-logo">
                    {{ stockData ? stockData.name : "-----" }} ({{
                      items.length
                    }})
                  </h3>
                </div>
              </div>

              <!-- Header: Right Content -->
              <div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  size="sm"
                  @click="repeateAgain"
                  :disabled="remainProducts.length <= 0"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>Nouvelle Entrée</span>
                </b-button>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="stock-spacing" />

          <!--          Form-->
          <b-form
            ref="refFormProvideObserver"
            :style="{ height: trHeight }"
            class="repeater-form"
            @submit.prevent="submitFormProvider"
            @reset="resetFormProvider"
          >
            <b-card-body class="stock-padding form-item-section">
              <!-- Row Loop -->
              <b-row
                v-for="(item, index) in items"
                :id="index"
                :key="index"
                ref="row"
              >
                <!-- Product Name -->
                <b-col md="4">
                  <b-form-group label="Produit" label-for="product">
                    <v-select
                      id="product"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="remainProducts"
                      label="name"
                      :clearable="false"
                      class="mb-2 item-selector-title"
                      placeholder="Sélectionner un produit"
                      @input="(val) => addProduct(index, val)"
                    />
                  </b-form-group>
                </b-col>

                <!-- Product State -->
                <b-col md="2">
                  <b-form-group label="État" label-for="state">
                    <b-form-select
                      v-model="item.condition"
                      :options="conditionOptions"
                    />
                  </b-form-group>
                </b-col>

                <!-- Quantity -->
                <b-col md="2">
                  <b-form-group label="Quantité" label-for="quantity">
                    <b-form-input
                      id="quantity"
                      v-model="item.quantity"
                      type="number"
                      placeholder="1"
                    />
                  </b-form-group>
                </b-col>

                <!-- Available -->
                <b-col lg="2" md="1">
                  <b-form-group label="Disponible" label-for="available">
                    <b-form-input
                      :id="`available-${index}`"
                      v-model="item.available_qty"
                      plaintext
                    />
                  </b-form-group>
                </b-col>

                <!-- Remove Button -->
                <b-col lg="2" md="3" class="mb-50">
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index, item)"
                  >
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Supprimer</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
            </b-card-body>

            <b-card-footer>
              <div class="d-flex flex-row justify-content-between mb-75">
                <div>
                  <b-button variant="warning" size="sm" @click="goBack">
                    <feather-icon icon="CornerUpLeftIcon" />
                    Retour
                  </b-button>
                </div>
                <div>
                  <b-button
                    variant="danger"
                    size="sm"
                    type="reset"
                    :disabled="items.length <= 0"
                  >
                    <feather-icon icon="XCircleIcon" />
                    Effacer
                  </b-button>
                  <b-button
                    variant="success"
                    class="ml-3"
                    size="sm"
                    type="submit"
                    :disabled="items.length <= 0 || isLoading"
                  >
                    <b-spinner v-if="isLoading" small></b-spinner>
                    <feather-icon v-else icon="SaveIcon" />
                    <span> Sauvegarder</span>
                  </b-button>
                </div>
              </div>
            </b-card-footer>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BButton,
  BCardBody,
  BCardFooter,
  BSpinner,
} from "bootstrap-vue";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import store from "@/store";

import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { createNamespacedHelpers } from "vuex";

const { mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers("stock");

export default {
  components: {
    BSpinner,
    BCardFooter,
    BCard,
    BCardBody,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,

    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      isLoading: false,
      conditionOptions: [
        {
          value: "new",
          text: "Nouveau",
        },
        {
          value: "used",
          text: "Utilisé",
        },
        {
          value: "repaired",
          text: "Réparé",
        },
      ],
      items: [
        {
          product: null,
          state: "",
          provider: null,
          quantity: 0,
          available_qty: 0,
          condition: null,
        },
      ],
      remainProducts: [],
      nextTodoId: 2,
    };
  },
  watch: {
    getError: {
      handler: function (val) {
        if (val) {
          this.showToast(val.message, "AlertTriangleIcon", "danger");
          this.handleErrors(val.error);
        }
      },
      immediate: true,
      deep: true,
    },
    availableProducts: {
      handler: function (val) {
        this.remainProducts = [...val];
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      stockData: "stock",
      allStockProducts: "products",
      getError: "errors",
    }),
    // products list
    availableProducts() {
      return this.$store.getters["item/all"];
    },
  },
  mounted() {
    this.initTrHeight();
    this.getStock();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    ...mapActions({
      fetchStock: "fetchStock",
    }),
    goBack() {
      this.$router.go(-1);
    },
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index, item) {
      this.items.splice(index, 1);
      if (item.product) {
        this.updateItemFromAvailableProducts(item.product);
      }
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.refFormProvideObserver.scrollHeight);
      });
    },
    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant,
        },
      });
    },
    handleErrors(errors) {
      for (const [field, message] of Object.entries(errors)) {
        this.$refs.refFormProvideObserver.setErrors({ [field]: message[0] });
      }
    },
    async getStock() {
      this.isLoading = true;
      try {
        await this.fetchStock({
          store_id: this.$route.params.storeID,
          data: {
            id: this.$route.params.id,
          },
        });
        await this.getAvailableProducts();
      } catch (e) {
        this.showToast(
          "Une erreur est survenue à la récupération du stock.",
          "AlertTriangleIcon",
          "danger"
        );
      } finally {
        this.isLoading = false;
      }
    },
    async getAvailableProducts() {
      try {
        await this.$store.dispatch("item/fetchAllItems");
      } catch (e) {
        this.showToast(
          "Une erreur est survenue à la récupération de la liste des produits.",
          "AlertTriangleIcon",
          "danger"
        );
      }
    },
    getAvailableProduct(index, uid) {
      if (this.allStockProducts.length <= 0)
        return 0;

      const currProduct = this.allStockProducts.find((p) => p.uid === uid);
      if (currProduct === undefined) return 0;

      return currProduct?.pivot.in_stock;
    },
    addProduct(index, val) {
      if (this.items[index].product) {
        this.updateItemFromAvailableProducts(this.items[index].product);
      }
      // update provide form by adding new product
      this.items[index].product = val.uid;

      // get available quantity of selected product
      this.items[index].available_qty = this.getAvailableProduct(
        index,
        val.uid
      );

      // remove the current product from available products list
      const productIndex = this.remainProducts.findIndex(
        (s) => s.uid === val.uid
      );
      if (productIndex !== -1) this.remainProducts.splice(productIndex, 1);
    },
    updateItemFromAvailableProducts(productUID) {
      const productFounded = this.availableProducts.find(
        (p) => p.uid === productUID
      );
      if (productFounded) this.remainProducts.unshift(productFounded);
    },
    resetFormProvider() {
      // init items form
      this.items = [
        {
          product: null,
          state: "",
          provider: null,
          quantity: 0,
          available_qty: 0,
          condition: null,
        },
      ];
      this.remainProducts = [...this.availableProducts];
    },
    async submitFormProvider() {
      try {
        this.isLoading = true;
        const { message } = await this.$store.dispatch("stock/saveProviding", {
          store: this.$route.params.storeID,
          stock: this.$route.params.id,
          data: { items: this.items },
        });

        this.showToast(message, "CheckIcon", "success");
        await this.$router.push({
          name: "apps-store-details",
          params: { id: this.$route.params.storeID },
        });
      } catch (e) {
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
